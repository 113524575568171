import * as React from "react";
import { getColor } from "../utils/getColor";
import { atom, useSetAtom } from "jotai";


const linesAtom = atom<Set<LeaderLine>>(new Set([]));

const addLineAtom = atom(null, (get, set, line: LeaderLine) => {
  const lines = get(linesAtom);
  lines.add(line);
  set(linesAtom, lines);
});
const removeLineAtom = atom(null, (get, set, line: LeaderLine) => {
  const lines = get(linesAtom);
  line.hide();
  lines.delete(line);
  line.remove();
  set(linesAtom, lines);
});

const clearLinesAtom = atom(null, (get, set) => {
  const lines = get(linesAtom);
  lines.forEach((line) => {
    set(removeLineAtom, line);
  });
});

const updatePositionAtom = atom(null, (get) => {
  const lines = get(linesAtom);
  lines.forEach((line) => {
    line.position();
  });
});

export const useLeaderLineUtil = () => {
  const updatePosition = useSetAtom(updatePositionAtom);
  const add = useSetAtom(addLineAtom);
  const remove = useSetAtom(removeLineAtom);
  const clear = useSetAtom(clearLinesAtom);

  return {
    updatePosition,
    add,
    remove,
    clear,
  }
}

export function useLeaderLine<T extends HTMLElement>() {
  const currentLines = React.useRef<any>(null);
  const addLine = useSetAtom(addLineAtom);
  const removeLine = useSetAtom(removeLineAtom);
  const sourceRef = React.useRef<T>(null);

  const getGradient = React.useCallback(() => {
    if (!sourceRef.current) {
      return {
        startColor: "#66666680",
        endColor: "#66666680",
      }
    }
    const parentId = sourceRef.current.dataset.parentId;
    const parent = document.querySelector(`[data-id="${parentId}"]`);
    if (!parent) {
      return;
    }

    return {
      startColor: getColor(getComputedStyle(parent!)?.getPropertyValue("border-color")) ?? "#66666680",
      endColor: getColor(getComputedStyle(sourceRef.current)?.getPropertyValue("border-color")) ?? "#66666680",
    };
  }, []);

  React.useEffect(() => {
    if (sourceRef.current === null) {
      return;
    }

    function callback(mutationList, observer) {
      mutationList.forEach((mutation) => {
        switch (mutation.type) {
          case "childList":
            break;
          case "attributes":
            const gradient = getGradient();
            currentLines.current?.setOptions({ // element-3, element-4
              gradient
            });
            break;
        }
      });
    }

    const observer = new MutationObserver(callback);
    observer.observe(sourceRef.current, {
      childList: true,
      attributes: true,

      // Omit (or set to false) to observe only changes to the parent node
      subtree: true,
    });
    return () => {
      observer.disconnect();
    }

  }, []);

  React.useLayoutEffect(() => {
    if (sourceRef.current === null || !sourceRef.current.dataset.parentId) {
      return;
    }

    const parentId = sourceRef.current.dataset.parentId;
    const parent = document.querySelector(`[data-id="${parentId}"]`);
    if (!parent) {
      return;
    }

    let line = new LeaderLine(parent, sourceRef.current, {
      endPlug: "behind",
      path: "fluid",
      gradient: getGradient(),
      startSocket: "right",
      endSocket: "left",
      startSocketGravity: 40,
      hide: true,
      fade: { duration: 3000, timing: 'linear' },
      endSocketGravity: 40,
    }
    );
    line.show();
    currentLines.current = line;
    addLine(line);

    return () => removeLine(line);
  }, []);
  return sourceRef;
}


