import * as React from "react";
import classNames from "clsx";
import { twMerge } from 'tailwind-merge'

import { useLeaderLine } from "../hooks/useLeaderLine";
import { useSetDetails } from "../stores";
import { useFormData } from "../stores/form";
import { ModalTriggerButton } from "@components/Dialog";

export type LeafProps = {
  data: {
    nodeType: "leaf";
    _id: string;
    name: string;
    parentId: string;
    highestForGoal: { slug: string }[];
    status: "published" | "prepublished";
    category: "students" | "curriculumAndPedagogy" | "programs" | "department";
  },
  className?: string;
  tabIndex: number;
  children?: React.ReactElement | boolean | "";
}

export const Leaf = ({ data, tabIndex, className = "" }: LeafProps) => {
  const formData = useFormData();
  const setDetails = useSetDetails();
  const leafRef = useLeaderLine<HTMLDivElement>();
  const published = data.status === "published";
  const disabled = !formData.testAreaOfFocus(data.category) || !formData.testImpact(data.highestForGoal?.findIndex((item) => {
    if (typeof window === "undefined") return false;
    const lastOption = window.location.pathname.split("/").filter((i) => i).slice(-1)[0];
    return item.slug === lastOption;
  }) >= 0);//highestForGoal)
  const active = false;

  const newClassName = classNames(
    "group/leaf-container",
    {
      "group/dark-colors": ["students", "curriculumAndPedagogy", "department"].includes(data.category),
      "group/light-colors": !["students", "curriculumAndPedagogy", "department"].includes(data.category),
    },
    "hover:outline active:outline",
    "outline-offset-2",
    "leading-none",
    "border-[rgb(var(--category-color))]",
    "hover:bg-[rgb(var(--category-color))]",
    "focus-within:bg-[rgb(var(--category-color))]",
    {
      "disabled mouse-event-none": disabled,
      "active outline": active,
      "outline-slate-400": !["students", "curriculumAndPedagogy", "programs", "department"].includes(data.category),
      "outline-[rgb(var(--category-color))]": ["students", "curriculumAndPedagogy", "programs", "department"].includes(data.category),
    },
    className,
    "rounded",
    "font-roboto",
    "border-[4px]",
    "w-[200px]",
    "text-xs",
    "flex justify-between items-center",
    "data-[published=false]:bg-category-stripe-light",
    {
      "data-[published=false]:hover:bg-category-stripe-dark": !disabled,
      "data-[published=false]:focus-within:bg-category-stripe-dark": !disabled,
      "border-[rgba(var(--category-color),_.4)] hover:bg-transparent outline-none outline-transparent": disabled,
    }

  );
  return (
    <div
      ref={leafRef}
      id={data._id}
      data-published={published}
      data-parent-id={data.parentId}
      data-id={data._id}
      style={{
        // Set up category css variable from existing css variable
        // @ts-ignore
        "--category-color": `var(--${data.category?.startsWith("curriculum") ? "curriculum" : data.category}-color)`,
      }}
      className={twMerge(newClassName)}
    >
      <ModalTriggerButton
        data-ep3-track="goal-map-button"
        className={`
          block w-full text-left px-2 py-1 text-xs

          text-black
            group-[.active]/leaf-container:underline
          group-data-[published=false]/leaf-container:text-slate-700
          group-data-[published]/leaf-container:focus:font-semibold
          group-data-[published=false]/leaf-container:group-hover/dark-colors:text-white
          group-focus-within/dark-colors:text-white
          group-hover/dark-colors:text-white
          disabled:bg-zinc-200
          group-[&.disabled]/leaf-container:text-slate-700
          group-[&.disabled]/leaf-container:mouse-event-none
          disable:mouse-event-none
            disabled:opacity-50

        `}
        onClick={() => setDetails(data)}
        disabled={disabled}
        tabIndex={disabled ? undefined : tabIndex}
        target="detail-modal"
      >
        {data.name}
      </ModalTriggerButton>
    </div>
  );
};
