import * as React from "react";

export const Checkbox = ({ title, onChange, value }: { title: string; onChange: any; value: string }) => {
  const ep3Track = title === "Show only highest impact for this goal" ? "goal-map-high-impact" : undefined;
  return (
    <label data-ep3-track={ep3Track} className="block cursor-pointer">
      <input onChange={onChange} type="checkbox" className="px-2" checked={!!value} />
      <span className="ml-2 ">{title}</span>
    </label>
  );
};
