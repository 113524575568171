import * as React from "react";

import classNames from "clsx";

const LeafCategoryPillText = (type = "", defaultResult: any) => {
  switch (type) {
    case "students":
      return "Students";
    case "curriculumAndPedagogy":
      return "Curriculum";
    case "programs":
      return "Programs";
    case "department":
      return "Departments";
    default:
      return defaultResult;
  }
};

export const CategoryPill = ({ category, children }: { category?: string, children?: any }) => {
  const className = classNames(`rounded-full py-1 px-3 text-sm text-sans`, {
    "text-white bg-students": category === "students",
    "text-white bg-curriculum": category === "curriculumAndPedagogy",
    "text-[#333] bg-programs": category === "programs",
    "text-white bg-department": category === "department",
    "text-white bg-slate-600": !["students", "curriculumAndPedagogy", "programs", "department"].includes(category ?? ""),
  });
  return <span className={className}>{LeafCategoryPillText(category, children)}</span>;
};
