import * as React from "react";
import classNames from "clsx";
import { Branch, type BranchProps } from "./Branch";
import { Leaf, type LeafProps } from "./Leaf";
import { useRootId } from ".";

type TreeProps = {
  data: (BranchProps["data"] | LeafProps["data"])[];
  tabIndex?: number;
};

type TreeNodeProps = {
  data: BranchProps["data"] | LeafProps["data"]; // for what ever reason this is not assignable to never
  tabIndex: number;
  className?: string;
  root?: boolean;
  children?: React.ReactElement | boolean | "";
};

export const TreeNode = ({ data, className = "", tabIndex, children, root }: TreeNodeProps) => {
  const newClassName = classNames(
    "entry",
    className,
    "grid",
    "w-full",
    "grid-cols-[200px_1fr]",
    "items-center",
    "font-roboto",
    "gap-1",
    {
      "[column-gap:1.25em] lg:[column-gap:4em] grid-template:[auto_1fr]": data.nodeType === "branch",
      [`children-${data.nodeType === "branch" && data.children?.length}`]: data.nodeType === "branch",
    }
  );

  const childClasses = classNames(className, { "text-center max-w-[25ch]": data.nodeType === "branch" && !root })

  return (
    <li className={newClassName}>
      {data.nodeType === "branch" && <Branch data={data} tabIndex={tabIndex} className={childClasses} children={children} />}
      {data.nodeType === "leaf" && <Leaf data={data} tabIndex={tabIndex} className={childClasses} />}
    </li>
  );
};

export const Tree = ({ data, tabIndex = 0 }: TreeProps) => {
  const source = useRootId();
  return (
    <ul className="branch grid grid-rows-[auto]">
      {data.map((branch: any) => {
        return <TreeNode key={source + "." + branch._id} data={branch} root={true} tabIndex={tabIndex} />;
      })}
    </ul>
  );
};
