import { useAtomCallback, useReducerAtom } from "jotai/utils";
import { atom, useAtom } from "jotai";
import { useCallback, useEffect } from "react";
import { pathAtom } from "@/store";

export const goalMapPathAtom = atom<string | undefined>(undefined);
const derivedPathAtom = atom(
  (get) => {
    return get(goalMapPathAtom);
  },
  (_, set, path: string) => {
    typeof history !== "undefined" && history.pushState({}, "", "/goal-map/" + path);
    set(goalMapPathAtom, path);
    set(pathAtom, "/goal-map/" + path);
    window.dispatchEvent(new Event("pushstate"));
    window.setTimeout(() => {
      document.querySelector("#goal-map-container")?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 700);
  }
);

const formAtom = atom({
  showUpcoming: true,
  showDetails: true,
  category: undefined,
  categoryList: [],
  importance: undefined,
  importanceList: [],
});

const addToList = (initList: any[], value: string) => {
  const list = new Set(initList);
  list.add(value);
  return [...list];
};

const removeFromList = (initList: any[], value: string) => {
  const list = new Set(initList);
  list.delete(value);
  return [...list];
};
const GoalMapFormReducer = (state, { type, payload }) => {
  switch (type) {
    case "IMPACT_SELECTION:ADD":
      return {
        ...state,
        importance: payload,
        importanceList: addToList(state.importanceList, payload),
      };
    case "CATEGORY_SELECTION:ADD":
      return {
        ...state,
        category: payload,
        categoryList: addToList(state.categoryList, payload),
      };
    case "IMPACT_SELECTION:DELETE":
      return {
        ...state,
        importance: undefined,
        importanceList: removeFromList(state.importanceList, payload),
      };
    case "IMPACT_SELECTION:SET":
      return {
        ...state,
        importanceList: payload,
      };
    case "CATEGORY_SELECTION:DELETE":
      return {
        ...state,
        category: undefined,
        categoryList: removeFromList(state.categoryList, payload),
      };
    case "TOGGLE_UPCOMING":
      return { ...state, showUpcoming: !state.showUpcoming };
    case "TOGGLE_DETAILS":
      return { ...state, showDetails: !state.showDetails };
    case "RESET":
      return {
        showUpcoming: true,
        category: undefined,
        categoryList: [],
        importance: undefined,
        importanceList: [],
      };
    default:
      return state;
  }
};

export const useFormAtom = () => {
  const [state, dispatch] = useReducerAtom(formAtom, GoalMapFormReducer);
  const reset = () => dispatch({ type: "RESET" }); /// useResetAtom(formAtom);
  return [state, dispatch, reset];
};

export const useFormData = () => {
  const [state] = useAtom(formAtom);
  return {
    state,
    testAreaOfFocus: (category) => {
      if (state.categoryList.length === 0) return true;
      return state.categoryList.includes(category);
    },
    testImpact: (impact) => {
      if (state.importanceList.length === 0) return true;
      return impact;// state.importanceList.includes(impact);
    },
  }
}

export const useGoalMapPathIdx = () => {
  return  useAtom(derivedPathAtom);
};

// Setup the Goal Map Path
export const useGoalMapPath = (path: string) => {
  const callback = useAtomCallback(
    useCallback(
      (_, set) => {
        return set(goalMapPathAtom, path.split("/").slice(-1)[0]);
      },
      [path]
    )
  );
  useEffect(() => {
    callback();
  }, [callback]);
};
