/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable functional/immutable-data */
import * as React from "react";
import { BsCaretDownFill } from "react-icons/bs";
import classNames from "clsx";

type MultiSelectAreaProps<T> = {
  value: T;
  getChoice: (args: any) => [any, any];
  title: string;
  truncate?: boolean;
  options: any;
  className: string;
  onAdd: ([any, any]) => void;
  onRemove: ([any, any]) => void;
  isSelected?: ([any, any]) => void;
};

export const MultiSelectArea = ({
  value,
  getChoice,
  title,
  options,
  onAdd,
  onRemove,
  isSelected,
  truncate,
  className = "",
}: MultiSelectAreaProps<any>) => {
  const [display, setDisplay] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    // if (!isSafari) {
    //   return;
    // }
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        setDisplay(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const onClick = React.useCallback((val, fn) => {
    fn(val);
  }, []);
  const displayValue = React.useMemo(() => {
    const title = options
      .reduce((acc: string[], x: any) => {
        const choice = getChoice(x);
        const [id, label] = choice;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        const selected = isSelected ? isSelected(choice) : value === id || value?.includes(id);
        selected && acc.push(label);
        return acc;
      }, [])
      .join(", ");
    return truncate && title.length > 20 ? title.slice(0, 17) + "..." : title;
  }, [getChoice, isSelected, options, truncate, value]);

  return (
    <div ref={ref} className="group">
      <h3 className="text-base font-medium m-0 mb-2">{title}</h3>

      <button
        // @ts-ignore
        onClick={(evt: React.MouseEvent<HTMLButtonElement>) => setDisplay(true)}
        className={classNames(
          { "w-[21ch]": truncate },
          className,
          "min-w-[21ch]",
          "max-w-full",
          "relative",
          "z-0",
          "bg-white",
          "border",
          "p-2",
          "mb-2",
          "w-full",
          "flex",
          "flex-row",
          "justify-between",
          "items-center",
          "peer"
        )}
      >
        <h3 className="text-base font-medium m-0 text-black">{displayValue || "Any"}</h3>
        <BsCaretDownFill />
      </button>

      <div
        data-ep3-track="goal-map-dropdown"
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        className={classNames(
          "options",
          "z-50",
          "absolute",
          "bg-white",
          "rounded-lg",
          "min-w-[21ch]",
          "w-[max-content]",
          "border",
          "border-primary",
          "group-focus-within:block peer-focus:block focus-within:block focus:block",
          { block: display, hidden: !display }
        )}
      >
        {options.map((baseChoice: any) => {
          const choice = getChoice(baseChoice);
          const [id, label] = choice;
          const selected = isSelected ? isSelected(choice) : value === id || value?.includes(id);
          return (
            <button
              key={"sel-" + id}
              value={label}
              onClick={() => (!selected ? onClick(choice, onAdd) : onClick(choice, onRemove))}
              className={classNames(
                "block",
                "flex",
                "px-2",
                "justify-between",
                "w-full",
                "text-left",
                "px-2",
                "hover:bg-primary",
                "hover:text-white",
                "cursor-pointer",
                "first:rounded-t-lg",
                "last:rounded-b-lg"
              )}
            >
              <span className="label">{label}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};
