
import { atomsWithQuery } from 'jotai-tanstack-query'

import React from "react";
import { goalMapPathAtom } from "./form";
import { client } from "../client";
import { useAtom } from "jotai";

type useGoalMapGraphResultType<T> = {
  graph: T[] | undefined;
  label: string | null;
  selectedIndex: number;
  updateSelectedIndex: (args: number) => void;
  updateEdges: (args: T[]) => void;
};

const [goalMapGraphAtom] = atomsWithQuery((get) => ({
  queryKey: ["goalMapGraph", get(goalMapPathAtom)],
  queryFn: async ({ queryKey: [, slug] }) => {
    if(!slug) {
      return []
    }

  const queryObjProps = `
    "parentId": ^._id,
    ...select(_type == "EP3Document" => {
      _id,
      "id": _id,
      "nodeType": "leaf",
      "name": coalesce(name, seo.title),
      "title": coalesce(seo.title, name),
      "slug": slug.current,
      importance,
      "category": string::split(category, ".")[0],
      importanceList,
      "thematicGroupings": effectivePractices[].title,
      "description": coalesce(description, altDescription, ""),
      "status": select(
        publicationStatus == "live" => "published",
        "prepublished"
      ),
      "highImpact": true in highestForGoal[]->{
        _id,
        "slug": slug.current
      }{
        "value": slug == "improve-student-satisfaction-or-engagement"
      }.value,
      "highestForGoal": highestForGoal[]-> {
        _id,
        "slug": slug.current
      },
    },
    {
      _id,
      "nodeType": "branch",
      "name": coalesce(goalMapName, title),
      importance,
      "slug": slug.current,
    })`;
  const query = `*[slug.current==$slug]{
    "icon": svgUpload,
    ${queryObjProps},
    "children": pathsToSuccess[]-> {
      ${queryObjProps},
"children": pathsToSuccess[]-> {
      ${queryObjProps},
"children": pathsToSuccess[]-> {
      ${queryObjProps},
"children": pathsToSuccess[]-> {
      ${queryObjProps},
"children": pathsToSuccess[]-> {
      ${queryObjProps},
"children": pathsToSuccess[]-> {
      ${queryObjProps},

    }
    }
    }
    }
    }
    }
  }`;

  return client.fetch(query, { slug  })

  }
}));


export const useGoalMapGraph = function <T>(edges: any): useGoalMapGraphResultType<T>["graph"] {

  const [graph] = useAtom(goalMapGraphAtom);

  return graph;
};
