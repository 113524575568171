import * as React from "react";
import { encodeSvg } from "./utils/encodeSvg";
import { useGoalMapPathIdx } from "./stores/form";

export const TopGoalMapNav = ({ goals }: { goals: any }) => {
  const [pathIdx, setPathIdx] = useGoalMapPathIdx();
  const [cssVars, setCssVars] = React.useState<any>({});
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }
    const cssVarUpdate = {
      primaryColor: getComputedStyle(ref.current)?.getPropertyValue("--primary")?.replace("#", "").trim() ?? "4652A4",
      secondaryColor:
        getComputedStyle(ref.current)?.getPropertyValue("--secondary")?.replace("#", "").trim() ?? "D241A0",
    };
    cssVarUpdate.primaryColor = cssVarUpdate.primaryColor.length > 0 ? cssVarUpdate.primaryColor : "4652A4";
    cssVarUpdate.secondaryColor = cssVarUpdate.secondaryColor.length > 0 ? cssVarUpdate.secondaryColor : "4652A4";
    setCssVars(cssVarUpdate);
  }, [ref.current]);
  return (
    <ul ref={ref} className="grid grid-cols-2 md:grid-cols-3 2xl:flex 2xl:flex-[1_1_400px] w-full gap-1" data-ep3-track="goal-map-tab">
      {goals && goals.map(
        ({ goalMapName, svgUpload, slug }: { goalMapName: string; svgUpload: string; slug: { current: string } }) => {
          const [svgString, setSVGString] = React.useState<string>(`data:image/svg+xml,%3Csvg xmlns%3D'http%3A//www.w3.org/2000/svg' styles='height:100%25%3Bwidth:100%25'%3E %3Cdefs%3E %3ClinearGradient id='myGradient' gradientTransform='rotate(20)'%3E %3Cstop offset='5%25' stop-color='%23eee'%3E %3Canimate attributeName='stop-color' values='%23EEEEEE; %23CCCCCC; %23EEEEEE' dur='2s' repeatCount='indefinite'%3E%3C/animate%3E %3C/stop%3E %3Cstop offset='95%25' stop-color='%23aaa'%3E %3Canimate attributeName='stop-color' values='%23EEEEEE; %23DDDDDD; %23EEEEEE' dur='3s' repeatCount='indefinite'%3E%3C/animate%3E %3C/stop%3E %3C/linearGradient%3E %3C/defs%3E %3Crect fill='url(%23myGradient)' height='100%25' width='100%25' /%3E %3C/svg%3E`);
          const isActive = slug?.current === pathIdx;
          React.useEffect(() => {
            setSVGString("data:image/svg+xml," + encodeSvg(svgUpload?.replace(
              new RegExp(isActive ? cssVars.primaryColor : "FFFFFF", "ig"),
              isActive ? "FFFFFF" : cssVars.primaryColor
            )
              .replace("currentColor", isActive ? "#FFF" : "#" + cssVars.primaryColor)));
          }, [pathIdx, svgUpload]);
          return (
            <li
              className={`
                  w-full h-full
                  inline-block
                  font-bold
                  lg:pb-0 pb-4 px-4

                 `}
              key={goalMapName}
            >
              <button
                onClick={() => setPathIdx(slug?.current)}
                className={`group
                    leading-none
                    flex flex-col justify-between
                    items-center
                    min-w-[160px]
                    h-[150px]
                    w-full
                    p-4 self-stretch cursor-default
                    text-base
                    border lg:border-b-0 lg:rounded-b-none rounded-md ${isActive
                    ? "border-primary bg-primary"
                    : "border-secondary hover:bg-slate-300 hover:cursor-pointer"
                  } `}
              >
                <img className="w-12 h-12 mx-auto" src={svgString} />
                <span className={`${isActive ? "text-white text-xs lg:text-base" : "text-black text-xs lg:text-base"}`}>
                  {goalMapName}
                </span>
              </button>
            </li>
          );
        }
      )}
    </ul>
  );
};
