import * as React from "react";
// import { Layout } from "@/components/Layout";
import { heroAtom, pathAtom, seoAtom } from "@/store";
import { graphql } from "gatsby";
import { BasePage, Edges, Maybe } from "@/types/graphql";
import { SanityGoal } from "@/components/GoalMap/types";
import { TopGoalMapNav } from "@components/GoalMap/TopGoalMapNav";
import { useGoalMapGraph } from "@components/GoalMap/stores/goalMapGraph";
import { useGoalMapPath } from "@components/GoalMap/stores/form";
import { GoalMapForm } from "@components/GoalMap/Form";
import { FiExternalLink } from "react-icons/fi";
import { GoalGraphic, GoalMapLoading } from "@components/GoalMap";
import { Container } from "@components/Layout/Container";
import { Layout } from "@components/Layout";
import { atom, useAtom } from "jotai";
import { useHydrateAtoms } from 'jotai/utils';
import { atomsWithQuery } from 'jotai-tanstack-query';
import { q } from "groqd";

import { client } from "../components/GoalMap/client";

import { Helmet } from "react-helmet";
import { Header } from "@components/Header";
import { BreadCrumbs } from "@components/Breadcrumbs";
import { Title } from "@components/Header/Title";
import { capitalCase } from "change-case";
import { Suspense } from "react";
import { DynamicComponent } from "@components/DynamicComponent";
import { preambleAtom } from "@components/GoalMap/stores/usePreamble";
import { mobileNoteAtom } from "@components/GoalMap/stores/useMobileNote";

type GoalMapPageProps = {
  data: Maybe<{
    hero: any;
    content: {
      nodes: {
        content: any;
      }[];
    };
    formPreamble: {
      nodes: {
        content: any;
      }[];
    };
    mobileNote: {
      nodes: {
        content: any;
      }[];
    };
    allSanityGoal: Edges<SanityGoal>;
    footer?: Maybe<any>;
    sanityPage: Maybe<BasePage & { heading: Maybe<string>, hero: any }>;
  }>;
  path: any;
};


const { query, schema } = q("*")
  .filter(`_type == "Goal" && goalMapName=="root"`)
  .grab({
    pathsToSuccess: [`pathsToSuccess[]-> {
        title,
        slug,
        svgUpload,
        goalMapName,
      }`, q.array(q.object({
      title: q.string(),
      slug: q.object({ "current": q.string() }),
      svgUpload: q.string().nullable().optional(),
      goalMapName: q.string(),
    }))]
  });

const [goalsQueryAtom] = atomsWithQuery((get) => ({
  queryKey: ['baseGoals'],
  queryFn: async () => {
    const result = await client.fetch(query, {});
    return schema.parse(result)[0]?.pathsToSuccess ?? [];
  },
}))

const goalAtom = atom((get) => get(goalsQueryAtom) || []);

const MobileNotice = () => {
  const buttonClasses = "flex gap-2 px-4 py-2 bg-secondary rounded text-white mb-4 items-center justify-center";

  return (
    <div className="font-roboto hidden portrait:flex md:portrait:hidden flex-col items-center justify-center px-16 pt-8">
      <svg width="170" height="170" viewBox="0 0 170 170" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M35.4166 145.208V24.7917C35.4166 17 41.7916 10.625 49.5833 10.625H120.417C128.208 10.625 134.583 17 134.583 24.7917V145.208C134.583 153 128.208 159.375 120.417 159.375H49.5833C41.7916 159.375 35.4166 153 35.4166 145.208Z"
          fill="#37474F"
        />
        <path
          d="M120.417 21.25H49.5833C47.4583 21.25 46.0416 22.6667 46.0416 24.7917V145.208C46.0416 147.333 47.4583 148.75 49.5833 148.75H120.417C122.542 148.75 123.958 147.333 123.958 145.208V24.7917C123.958 22.6667 122.542 21.25 120.417 21.25Z"
          fill="#F3E5F5"
        />
        <path d="M77.9167 120.417L98.8125 95.625H57.0209L77.9167 120.417Z" fill="#D241A0" />
        <path
          d="M92.0834 56.6667C80.3959 56.6667 70.8334 66.2292 70.8334 77.9167V99.1667H85V77.9167C85 74.0209 88.1875 70.8334 92.0834 70.8334C95.9792 70.8334 99.1667 74.0209 99.1667 77.9167V85.0001H113.333V77.9167C113.333 66.2292 103.771 56.6667 92.0834 56.6667Z"
          fill="#D241A0"
        />
      </svg>

      <h3 className="text-2xl text-center font-bold">Please rotate your device</h3>
      <p className="text-center text-base">
        Turn your device to landscape orientation to see the interactive Goal Map.
      </p>
      <p className="text-center text-sm">
        If the page does not rotate, you may need to turn off your device’s orientation lock.
      </p>
      <a className={buttonClasses} href="https://support.google.com/accessibility/android/answer/6006961?hl=en">
        <FiExternalLink /> Android instructions
      </a>
      <a className={buttonClasses} href="https://support.apple.com/en-us/HT204547">
        <FiExternalLink /> iOS instructions
      </a>
    </div>
  );
};


const GoalMapGraph = () => {
  const [rootGoal] = useAtom(goalsQueryAtom);
  const [path] = useAtom(pathAtom);
  useGoalMapPath(path);
  const graph = useGoalMapGraph<any>(rootGoal);
  return (
    <div
      id="goal-map-container"
      className={`relative hidden landscape:grid md:portrait:grid p-0 overflow-x-visible max-w-[100vw] h-full w-full lg:grid-row-[auto_1fr]`}
    >
      <GoalMapForm goals={rootGoal} />
      <GoalGraphic tree={graph!} />
    </div>
  );
};

const GoalMapBreadCrumbs = () => {
  const [path] = useAtom(pathAtom);

  const [, goalMaps, page] = path.split("/");
  return (
    <>
      {!page && <BreadCrumbs paths={[{ url: "/", label: "Home" }]} title={capitalCase(goalMaps)} />}
      {page && (
        <BreadCrumbs
          paths={[
            { url: " / ", label: "Home" },
            { url: "/goal-map", label: capitalCase(goalMaps) },
          ]}
          title={capitalCase(page)}
        />
      )}
    </>
  );
};


const GoalMapSelectors = () => {
  const [goals] = useAtom(goalAtom);
  return <div>
    <TopGoalMapNav goals={goals} />
  </div>
};

const GoalMapPage = ({ data, path }: GoalMapPageProps) => {
  useHydrateAtoms([
    [heroAtom, data?.sanityPage?.hero ?? data?.hero],
    [seoAtom, data?.sanityPage?.seo],
    [pathAtom, path],
    [preambleAtom, data?.formPreamble?.nodes?.[0]?.content],
    [mobileNoteAtom, data?.mobileNote?.nodes?.[0]?.content],
  ])
  return (
    <Layout>
      <Helmet>
        <title> Goal Map | EP3 Guide</title>
      </Helmet>
      <Container>
        <MobileNotice />
        <div className="px-4 hidden landscape:flex md:portrait:flex flex-col justify-around h-full bg-white">
          <Header>
            <GoalMapBreadCrumbs />
            <Title>
              {/* Added x-padding because of a request to align the title with the body text 🙄 */}
              <span className="px-4">Map your program’s goals</span></Title>
          </Header>
          <div className="max-w-full px-4 ">
            <DynamicComponent blocks={data?.content?.nodes?.[0]?.content} />
          </div>
          <Suspense fallback={<div></div>}>
            <GoalMapSelectors />
          </Suspense>
        </div>
        <Suspense fallback={<GoalMapLoading />}>
          <GoalMapGraph />
        </Suspense>
      </Container>
    </Layout>

  );
};

export default GoalMapPage;

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "hero-goal-page.jpg" }) {
      ...heroImageFragment
    }

    sanityPage(slug: { current: { eq: "/goal-map" } }) {
      seo {
        title
        description
      }
      heading

      hero {
        asset: _rawAsset(resolveReferences: { maxDepth: 10 })
        crop: _rawCrop(resolveReferences: { maxDepth: 10 })
        hotspot: _rawHotspot(resolveReferences: { maxDepth: 10 })
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
    content: allSanityCustomTextBlock(filter: { target: { eq: "goal-map-intro"}}) {
      nodes {
        content: _rawContent(resolveReferences: {maxDepth: 10})
        target
      }
    }

    formPreamble: allSanityCustomTextBlock(filter: { target: { eq: "goal-map-form"}}) {
      nodes {
        content: _rawContent(resolveReferences: {maxDepth: 10})
        target
      }
    }

    mobileNote: allSanityCustomTextBlock(filter: { target: { eq: "goal-map-mobile-note"}}) {
      nodes {
        content: _rawContent(resolveReferences: {maxDepth: 10})
        target
      }
    }
  }
`;
