import * as React from "react";
import classNames from "clsx";
import { Tree } from "./Tree";
import { useLeaderLine } from "../hooks/useLeaderLine";
import type { LeafProps } from "./Leaf";
import { useFormData } from "../stores/form";

export type BranchProps = {
  data: {
    _id: string;
    nodeType: "branch";
    name: string;
    parentId?: string;
    children: (BranchProps["data"] | LeafProps["data"])[];
  },
  tabIndex: number;
  className?: string;
  children?: React.ReactElement | boolean | "";
}
export const Branch = ({ data, tabIndex, className = "", children }: BranchProps) => {
  const formData = useFormData();
  const branchRef = useLeaderLine<HTMLButtonElement>();
  const childrenDisabled = React.useMemo(() => {
    const allLeaves = (children, currentList) => {
      const leaves = children.filter((child) => child.nodeType === "leaf");
      const branches = children.filter((child) => child.nodeType === "branch");
      branches.forEach((branch) => {
        currentList = currentList.concat(allLeaves(branch.children, currentList));
      })
      currentList = currentList.concat(leaves)
      return currentList;
    }
    const leaves = allLeaves(data.children, []);
    return leaves.every((leaf) => {
      return !formData.testAreaOfFocus(leaf.category) || !formData.testImpact(leaf.highestForGoal?.findIndex((item) => {
        if (typeof window === "undefined") return false;
        const lastOption = window.location.pathname.split("/").filter((i) => i).slice(-1)[0];
        return item.slug === lastOption;
      }) >= 0);
    })
  }, [data.children, formData]);
  const buttonClasses = classNames(
    // "hover:bg-zinc-300 hover:text-black",
    className,
    "leading-none",
    "color-black",
    "border-[4px]",
    "border-slate-400",
    "rounded",
    "text-sm",
    "font-roboto",
    "px-2",
    "text-left",
    "h-[fit-content]",
    "items-center",
    "justify-center",
    "w-[200px]",
    // "max-w-[200px]",
    "px-2 py-1",
    "cursor-auto",
    { "opacity-40 border-slate-300": childrenDisabled }
    // "outline-slate-400 outline-offset-2 hover:outline"
  );

  return (
    <>
      <button ref={branchRef} data-parent-id={data.parentId} data-id={data._id}
        className={buttonClasses}
        tabIndex={tabIndex}
      >
        {children}
        <span className="text-xs">{data.name}</span>
      </button>
      <Tree data={data.children} tabIndex={tabIndex + 1} />
    </>
  );
};


