export const getColor = (colorString: string) => {
  if (colorString.startsWith("#")) {
    return colorString;
  }
  if (colorString.startsWith("rgb")) {
    return (
      "#" +
      colorString
        .replace(/[^0-9,]/g, "")
        .split(",")
        .map((c) => String(parseInt(c.toString(), 10).toString(16)).padStart(2, "0"))
        .join("")
    );
  }
  return new Error("Color not supported");
};
