import * as React from "react";
import { TreeNode } from "./Tree";
import { encodeSvg } from "../utils/encodeSvg";
import { useLeaderLineUtil } from "../hooks/useLeaderLine";
import { atom, useAtomValue, useSetAtom } from "jotai";

type GoalMapTree = any;

const rootIdAtom = atom("");
const setRootId = atom(null, (get, set, id: string) => {
  set(rootIdAtom, id);
});

export const useRootId = () => useAtomValue(rootIdAtom)

export const RootTree = ({ data }: { data: GoalMapTree }) => {
  const { updatePosition, clear } = useLeaderLineUtil();
  const setRoot = useSetAtom(setRootId);

  React.useEffect(() => {
    setRoot(data._id);
  }, [data._id]);

  const icon = data?.icon ? `data:image/svg+xml,${encodeSvg(data.icon)}` : false;
  React.useEffect(() => {
    return () => clear();
  }, [])
  const [state, updateOnScroll] = React.useReducer((_: any, target: HTMLElement) => {
    updatePosition();
    return {
      offset: -1 * target.scrollLeft,
      hideLeftScroll: target.scrollLeft <= 10,
      hideRightScroll: target.scrollLeft + target.getBoundingClientRect().width >= target.scrollWidth - 4,
    }
  }, {
    offset: 0,
    hideRightScroll: false,
    hideLeftScroll: true,
  });

  return (
    <div className="goal-graphic relative z-0 overflow-scroll p-2 px-6 mt-20 lg:mt-0"
      onScroll={(e: React.SyntheticEvent): void => updateOnScroll(e.target as HTMLElement)}
      style={{ "--scroll-x-offset": `${state.offset}px` } as React.CSSProperties}
    >
      <ul>
        <TreeNode data={data} tabIndex={0} className="py-2" >
          {icon && <img src={icon} alt={"Icon for Root"} className="w-8 h-8 mb-2 mx-auto" />}
        </TreeNode>
      </ul>
    </div >
  );
};
