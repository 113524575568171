import React from "react";
import { DynamicComponent } from "@components/DynamicComponent";
import { HiOutlineArrowCircleRight } from "react-icons/hi";
import { useDetailsData } from "./stores";
import { EmailListFormAlt } from "@components/EmailListForm";
import { CategoryPill } from "../CategoryPill";
import { Modal, ModalTriggerButton } from "@components/Dialog";

const NavigationButton = ({ href }: { href: string }) => (
  <div className="flex items-start justify-between mt-4 mb-8">
    <a
      data-ep3-track="goal-map-panel"
      href={href}
      className="flex group/details-nav justify-center text-base items-center leading-6 border border-primary rounded-md hover:bg-primary"
    >
      <div className="px-5 py-3 font-bold group-hover/details-nav:text-white">Read this Section</div>
      <div className="bg-primary  p-3 group-hover/details-nav:bg-[#282E5D]">
        <HiOutlineArrowCircleRight className="inline-block min-w-[30px] min-h-[30px] text-white" />
      </div>
    </a>
  </div>
);

const DetailsElement = ({ data }) => {
  const published = data.status === "published";
  const highImpact = data.highestForGoal?.findIndex((item) => {
    if (typeof window === "undefined") return false;
    const lastOption = window.location.pathname.split("/").filter((i) => i).slice(-1)[0];
    return item.slug === lastOption;
  }) >= 0;
  return (
    <div className="mx-1 font-roboto ">
      <header className="text-3xl font-bold text-primary pt-0 mt-0 mb-4">
        <span className="text-secondary">{data.preamble}</span>{" "}
        {data.name}
      </header>
      {published && (<NavigationButton href={data.slug} />)}


      <div className={`flex gap-1 text-xs`}>
        <CategoryPill category={data.category} />
        {highImpact && <CategoryPill>High impact</CategoryPill>}
      </div>

      {!!data.thematicGroupings?.length && (
        <div>
          <header className="text-3xl font-bold text-black pt-0 my-4">
            Guide Section Themes
          </header>
          <ol className="list-decimal list-outside pl-4 text-xl">
            {data.thematicGroupings.map((x) => (
              <li key={x}>{x}</li>
            ))}
          </ol>
        </div>)}

      <div className={!published ? "hidden" : ""}>
        <header className="text-3xl font-bold text-black pt-0 my-4">
          In this Guide Section
        </header>
        <div className="list-none grid grid-cols-[auto_1fr] gap-y-1 gap-x-5 text-xl ">
          <span className="justify-self-end font-bold">{data.counts.effectivePractices}</span>
          <span> Thematic Groupings</span>
          <span className="justify-self-end font-bold">{data.counts.actionablePractices}</span>
          <span> Actionable Practices</span>
          <span className="justify-self-end font-bold">{data.counts.implementationStrategies}</span>
          <span> Implementation Strategies</span>
          <span className="justify-self-end font-bold">{data.counts.programmaticAssessments}</span>
          <span> Programmatic Assessments</span>
          <span className="justify-self-end font-bold">{data.counts.resources}</span>
          <span> Resources</span>
        </div>
      </div>

      {!published && (
        <div className="mt-4">
          <header className="text-black font-bold text-2xl leading-8">
            Coming Soon
          </header>
          <div className="mt-4">
            <DynamicComponent blocks={data.description} />
          </div>
          <EmailListFormAlt title="Get Email Updates" showIcon={false} className="bg-neutral-200 pb-8 self-center">
            This section is coming soon. Our quarterly newsletter keeps you in the loop about events, ways to get involved,
            and the latest EP3 Guide content.
          </EmailListFormAlt>
        </div>
      )}
    </div>
  );
};


export const Details = ({ }) => {
  const { state, data } = useDetailsData();
  const modalId = React.useId();

  return (
    <Modal id={modalId} className="bg-neutral-200 max-h-95vh h-95vh mt-[55px]">

      {data && (
        <div className="max-h-[90dvh] flex flex-col flex-grow min-w-fit max-w-2xl border-t-8 border-t-secondary">
          <div className="sticky flex justify-between items-center py-3 px-4 border-b dark:border-gray-700 bg-primary w-full">
            <div className="font-bold text-lg text-white">Guide Section Details</div>

            <ModalTriggerButton target={modalId} as="button" className="inline-flex flex-shrink-0 gap-2 justify-center items-center h-8 rounded-md text-primary hover:text-gray-400 bg-neutral-200 px-4">

              <svg className="w-3 h-3" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor" />
              </svg>
              <span>Close</span>
            </ModalTriggerButton>

          </div>
          <div className="overflow-y-auto flex-grow flex flex-col justify-between px-12 py-8">
            {state === 'loading' && (
              <div className="flex flex-col space-y-4">
                <div className="animate-pulse flex space-x-4">
                  <div className="flex-1 space-y-4 py-1">
                    <div className="h-4 bg-gray-300 dark:bg-gray-700 rounded w-3/4"></div>
                    <div className="space-y-2">
                      <div className="h-4 bg-gray-300 dark:bg-gray-700 rounded"></div>
                      <div className="h-4 bg-gray-300 dark:bg-gray-700 rounded w-5/6"></div>
                    </div>
                  </div>
                </div>
              </div>)}
            <DetailsElement data={data} />
          </div>
        </div>
      )}
    </Modal>

  )
};
