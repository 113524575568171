/* eslint-disable jsx-a11y/no-onchange */
import * as React from "react";
import { camelCase } from "camel-case";
import { MultiSelectArea } from "./MultiSelectArea";
import { Checkbox } from "./Checkbox";
import { useFormAtom, useGoalMapPathIdx } from "../stores/form";
import { AreaOfFocus } from "./AreaOfFocusSelection";
import * as styles from "../index.module.css";
import { usePreamble } from "../stores/usePreamble";
import { useMobileNote } from "../stores/useMobileNote";
import { DynamicComponent } from "@components/DynamicComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight, faFilter } from "@fortawesome/free-solid-svg-icons";
// import { faFilter } from "@fortawesome/free-regular-svg-icons";

export const GoalMapForm = ({ goals }: any) => {
  const refContainer = React.useRef<HTMLDivElement>(null);
  const mobileNote = useMobileNote();
  const preamble = usePreamble();
  const [state, dispatch, resetFields] = useFormAtom();
  const [goal, updateGoal] = useGoalMapPathIdx();
  const focusAreaChoices = ["Students", "Curriculum and Pedagogy", "Programs", "Department"];
  const multiselectGoals = React.useMemo(() => {
    return goals.map((goal: { goalMapName: any; slug: { current: any } }) => {
      return {
        label: goal.goalMapName,
        slug: goal.slug?.current ?? "",
      };
    });
  }, [goals]);

  const toggleFilters = React.useCallback(() => {
    if (!refContainer.current) {
      return;
    }

    refContainer.current.classList.toggle("max-h-0");
    refContainer.current.classList.toggle("max-h-full");

  }, []);

  return !goal ? null : (
    <div className="mb-12 lg:mb-6 border-primary border-t-8 lg:border-b w-full absolute lg:relative z-20">
      <div ref={refContainer} className="max-h-0 overflow-hidden lg:max-h-full lg:py-2 px-4 goal-map-form h-full max-w-[100vw] xl:bg-gray-200 bg-slate-100 z-50">
        <div className="hidden xl:block px-2 pb-4 w-7/12 [&_span.text-gray-600.block>h3]:text-2xl [&_span.text-gray-600.block>h3]:text-black  "><DynamicComponent blocks={preamble} /></div>
        <div className="pb-2 lg:py-0 px-2 flex flex-col lg:flex-row lg:items-center flex-wrap justify-between">
          <div className="w-full self-start gap-4 lg:gap-[2rem] text-black grid grid-rows-[auto_auto_auto_auto] lg:grid-rows-[auto_auto] grid-cols-1 lg:grid-cols-[2fr_1fr_2fr] xl:grid-cols-[2fr_1fr_2fr_1fr]">
            <MultiSelectArea
              title="My Goal is to..."
              value={goals}
              isSelected={(choice) => {
                return choice[0] === goal;
              }}
              options={multiselectGoals}
              onAdd={(value) => {
                updateGoal(value[0]);
              }}
              onRemove={() => {
                // noop
              }}
              getChoice={(obj) => [obj.slug, obj.label]}
              className="w-[46ch]"
            />
            <div className="place-self-start flex flex-col min-w-max leading-none">
              <h3 className="text-base font-medium m-0 block mb-2">Impact</h3>
              <Checkbox
                title="Show only highest impact"
                value={state.importanceList.includes(1)}
                onChange={(event: any) => {
                  dispatch({ type: "IMPACT_SELECTION:SET", payload: event.target.checked ? [0, 1, 2] : [] });
                }}
              />
            </div>
            <div className="">
              <div>
                <AreaOfFocus
                  truncate={true}
                  className={styles.areaOfFocus}
                  title="Areas of Focus"
                  value={state.categoryList}
                  options={focusAreaChoices}
                  onAdd={([payload]) => {
                    dispatch({ type: "CATEGORY_SELECTION:ADD", payload });
                  }}
                  onRemove={([payload]) => {
                    dispatch({ type: "CATEGORY_SELECTION:DELETE", payload });
                  }}
                  getChoice={(choice) => [camelCase(choice), choice]}
                />
              </div>
            </div>
            <div className="lg:col-span-3 xl:col-span-1 flex justify-end">
              <div className="self-end">
                <button
                  className="w-fit text-white text-lg bg-primary hover:bg-primary hover:opacity-75 active:bg-primary active:opacity-80 border py-2 px-4 min-w-max flex flex-cols justify-center items-center gap-2"
                  onClick={resetFields}
                >
                  <FontAwesomeIcon icon={faRotateRight} />
                  Reset
                </button>
              </div>
            </div>
          </div>
          <div className="hidden lg:block"> <DynamicComponent blocks={mobileNote} /> </div>
        </div>
      </div>
      <div className="bg-gray-200 lg:hidden flex justify-between p-2">
        <span>
          <DynamicComponent blocks={mobileNote} />
        </span>
        <button
          className="w-fit text-white text-sm bg-primary hover:bg-primary hover:opacity-75 active:bg-primary active:opacity-80 border py-2 px-4 min-w-max flex flex-cols justify-center items-center gap-2"
          onClick={toggleFilters}
        >
          <FontAwesomeIcon icon={faFilter} />
          Filters
        </button>
      </div>
    </div>
  );
};
