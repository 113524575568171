import * as React from "react";
import { RootTree } from "./Tree";
import { Details } from "./Details";
import { useGoalMapPathIdx } from "./stores/form";

export const GoalMapLoading = () => {
  // loading react component using div and tailwindcss
  // gives a less abrupt experience. May need to think on how to
  // expand on this as the whole page loads
  return <div>
    <div className="animate-pulse flex space-x-4 h-80">
      <div className="flex-1 space-y-4 py-1 self-center">
        <div className="h-4 bg-gray-200 rounded w-3/4"></div>
        <div className="space-y-2">
          <div className="h-4 bg-gray-200 rounded"></div>
          <div className="h-4 bg-gray-200 rounded w-5/6"></div>
        </div>
      </div>
      <div className="flex-1 space-y-4 py-1">
        <div className="space-y-2">
          <div className="h-4 bg-gray-200 rounded mb-12"></div>
          <div className="h-4 bg-gray-200 rounded w-5/6"></div>
        </div>
        <div className="space-y-2">
          <div className="h-4 bg-gray-200 rounded"></div>
          <div className="h-4 bg-gray-200 rounded w-2/6"></div>
        </div>
        <div className="space-y-2  mb-4">
          <div className="h-4 bg-gray-200 rounded w-5/6"></div>
          <div className="h-4 bg-gray-200 rounded"></div>
          <div className="h-4 bg-gray-200 rounded w-5/6 mb-8"></div>
        </div>
        <div className="space-y-2  mb-12">
          <div className="h-4 bg-gray-200 rounded w-2/6"></div>
          <div className="h-4 bg-gray-200 rounded w-5/6"></div>
        </div>
      </div>
      <div className="flex-1 space-y-4 py-1 self-center">
        <div className="h-4 bg-gray-200 rounded w-3/4 mb-16"></div>
        <div className="space-y-2">
          <div className="h-4 bg-gray-200 rounded"></div>
          <div className="h-4 bg-gray-200 rounded w-2/6"></div>
        </div>
        <div className="space-y-2 mb-16">
          <div className="h-4 bg-gray-200 rounded w-5/6"></div>
          <div className="h-4 bg-gray-200 rounded "></div>
        </div>
        <div className="space-y-2">
          <div className="h-4 bg-gray-200 rounded"></div>
          <div className="h-4 bg-gray-200 rounded w-5/6"></div>
        </div>
      </div>
    </div>
  </div>
}


export const GoalGraphic = ({ tree }: { tree: any[] }) => {
  const [currentPath] = useGoalMapPathIdx();
  const timeout = React.useRef<ReturnType<typeof setTimeout>>();
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    if (tree[0] === null) return;
    setData(null);
    timeout.current = setTimeout(() => setData(tree[0]), 1000);
    return () => clearTimeout(timeout.current);
  }, [tree[0]])
  return <>
    {data ? <RootTree data={data} /> : currentPath && <GoalMapLoading />}
    {/* Due to z-index issues we want the details out side of the main body,
       This is a helper that collects all content and will render them in
       the order that it receives them*/}
    <Details />
  </>
};
