import * as React from "react";
import classNames from "clsx";

type MultiSelectAreaProps<T> = {
  value: T;
  getChoice: (args: any) => [any, any];
  title: string;
  truncate?: boolean;
  options: any;
  className: string;
  onAdd: ([any, any]) => void;
  onRemove: ([any, any]) => void;
  isSelected?: ([any, any]) => void;
};

export const AreaOfFocus = ({
  value,
  getChoice,
  title,
  options,
  onAdd,
  onRemove,
  isSelected,
}: MultiSelectAreaProps<any>) => {
  const onClick = (val, fn) => fn(val);

  return (
    <div>
      <h3 className="text-base font-medium m-0 block mb-2">{title}</h3>
      <div data-ep3-track="goal-map-focus-area" className="flex flex-wrap max-w-[400px] gap-1">
        {options.map((baseChoice: any) => {
          const choice = getChoice(baseChoice);
          const [id, label] = choice;
          const selected = isSelected ? isSelected(choice) : value === id || value?.includes(id);
          return (
            <label
              key={"sel-" + id}
              className={classNames(
                "block",
                "flex",
                "flex-shrink",
                "max-w-fit",
                "items-center",
                "gap-2",
                "w-full",
                "leading-none",
                "text-left",
                "text-sm text-sans",
                "py-1 px-3",
                "cursor-pointer",
                "rounded-2xl",
                {
                  "bg-department text-black accent-department": id === "department",
                  "bg-students text-white accent-students": id === "students",
                  "bg-curriculum text-white  accent-curriculum": id === "curriculumAndPedagogy",
                  "bg-programs text-black  accent-programs": id === "programs",
                }
              )}
            >
              <input
                type="checkbox"
                key={"sel-" + id}
                className="[&:not(:checked)]:hidden peer/checkbox min-h-[14px] min-w-[14px]"
                value={id}
                checked={selected}
                onChange={() => (!selected ? onClick(choice, onAdd) : onClick(choice, onRemove))}
              />
              <div className={`peer-[:not(:checked)]/checkbox:block hidden min-h-[14px] min-w-[14px] border border-white`}></div>
              <span>{label}</span>
            </label>
          );
        })}
      </div>
    </div>
  );
};
