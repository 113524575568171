import { atom, useAtom } from "jotai";
import { loadable } from "jotai/utils"
import { client } from "../client";
import { q, z } from "groqd";

const {query, schema} =  q("*")
  .filter(`_type=="EP3Document" && $id == _id`)
  .grab({
      _id: q.string(),
      preamble: q.string().nullish(),
      goalMapName: q.string(),
      heading: q.string(),
      description: z.any(),
      publicationStatus: q.string(),
      "effectivePractices": ["count(coalesce(effectivePractices[], []))", q.number()],
      "actionablePractices":  ["count(coalesce(effectivePractices[].actionablePractices[], []))", q.number()],
      "implementationStrategies":  ["count(coalesce(effectivePractices[].actionablePractices[].implementationStrategies[], []))", q.number()],
      "programmaticAssessments": ["count(coalesce(programmaticAssessments, []))", q.number()],
      "resources": [`count(coalesce(resources[listItem=="bullet"], []))`, q.number()]
  })

import React from "react";
type DetailsObj = any;
const _detailsAtom = atom<DetailsObj | undefined>(undefined);
const asyncFetchCountsAtom = atom<Promise<undefined | DetailsObj>>(async (get) => {

  const details = get(_detailsAtom);
  if(!details) {
    return undefined;
  }
  const {_id, ...rest} = details;
  const data = schema.parse(await client.fetch(query, {id: _id}));
  const [{description, preamble, ...counts}] = data;
  return {
    ...rest,
    preamble,
    counts,
    description,
  };
})

const detailsAtom = loadable(asyncFetchCountsAtom);

export const useSetDetails = () => {
  const [_, setDetails] = useAtom(_detailsAtom);
  const set =  React.useCallback((args) => setDetails(args), [setDetails]);
  return set;
}

export const useDetailsData = () => {
  const [value] = useAtom(detailsAtom);
  if (value.state === "hasError") {
    console.error("error", value.error)
    return {
      state: "error",
      data: undefined,
      error: value.error,
    }
  }
  if (value.state === "loading") {
    return {
      state: "loading",
      data: undefined,
      error: undefined,
    }
  }
  return {
    state: "success",
    data: value.data,
    error: undefined,
  };
};
